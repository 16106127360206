import exit from "./icons/exit.webp"
import copy from "./icons/copy.svg"
import play from "./icons/play.svg"
import close from "./icons/close.svg"
import left_arr from "./icons/left_arr.svg"
import arrow_up from "./icons/arrow-up.svg"
import clock from "./icons/clock.svg"

import aeg from "./icons/aeg.svg"
import aeg_cards from "./icons/aeg_cards.svg"
import based from "./icons/based.svg"
import expand from "./icons/expand.svg"
import nft from "./icons/nft.svg"
import pause from "./icons/pause.svg"

import youtube from "./icons/youtube.svg"
import telegram from "./icons/telegram.svg"
import discord from "./icons/discord.svg"
import discordBtn from "./icons/discord-button.svg"
import twitter from "./icons/X.webp"
import instagram from "./icons/instagram.svg"
import down_arr from "./icons/down_arr.svg"
import right_arr from "./icons/right_arr.svg"

import menu from "./icons/menu.svg"
import Time_icon from "./icons/Time_icon.webp"

import border_arrow from "./images/miscs/border-arrow.webp"

// BACKGROUNDS
import rulesButton from "./images/backgrounds/rules.webp"
import rulesButtonHover from "./images/backgrounds/rules-hover.webp"
import discoverButton from "./images/backgrounds/discover.webp"
import discoverButtonHover from "./images/backgrounds/discover-hover.webp"
import viewAsNft from "./images/backgrounds/view-as-nft.webp"
import viewAsNftHover from "./images/backgrounds/view-as-nft-hover.webp"

import download from "./images/backgrounds/Download-button.webp"
import downloadHover from "./images/backgrounds/Download-button-hover.webp"

import btn_bg_dark from "./images/backgrounds/btn-bg-dark.webp"
import btn_bg_hover from "./images/backgrounds/btn-bg-hover.webp"
import btn_hover from "./images/backgrounds/button-hover.webp"

import age1 from "./images/backgrounds/age1.webp"
import age2 from "./images/backgrounds/age2.webp"
import age3 from "./images/backgrounds/age3.webp"

import age1_bg from "./images/backgrounds/age1_bg.webp"
import age2_bg from "./images/backgrounds/age2_bg.webp"
import age3_bg from "./images/backgrounds/age3_bg.webp"

import shineUnderButton from "./images/backgrounds/shine-small-hover-1th-age.webp"
import shine2UnderButton from "./images/backgrounds/shine-small-hover-2th-age.webp"
import shine3UnderButton from "./images/backgrounds/shine-large-hover-3th-age.webp"

import snow_effect from "./images/backgrounds/snow_effect.webp"

import download_bg from "./images/backgrounds/donload-bg.png"

import accept_btn from "./images/backgrounds/accept_btn.png"
import accept_hover from "./images/backgrounds/accept_hover.png"
import decline_btn from "./images/backgrounds/decline_btn.png"
import decline_hover from "./images/backgrounds/decline_hover.png"

// BLOG IMAGES
import immublImg from "./images/backgrounds/aeg_immutablex.webp"
import ultraImg from "./images/backgrounds/ultra-blogpost.webp"
import ultraScreenshot from "./images/backgrounds/ultra-screenshot.webp"
import ultiverse from "./images/backgrounds/uiltiverse-aether-games.webp"
import howToPlay from "./images/backgrounds/how-to-play.webp"
import emotesImg from "./images/backgrounds/emotes1.webp"
import aetherRaidsImg from "./images/backgrounds/aether-raids.webp"
import cardsImg from "./images/backgrounds/cards_blog_img.webp"
import emotesMediumImg from "./images/backgrounds/emotesMedium.webp"
import emotes2Img from "./images/backgrounds/emotes2.webp"
import emotesNFTUtility1 from "./images/backgrounds/emotesNftUtility1.webp"
import emotesNFTUtility2 from "./images/backgrounds/emotesNftUtility2.webp"
import emotesNFTUtility3 from "./images/backgrounds/emotesNftUtility3.webp"
import founderpacksBg from "./images/backgrounds/founderpacks.webp"
import openBetaFaqBg from "./images/backgrounds/openbetafaq.png"
import battleSigns from "./images/backgrounds/battlesigns.jpg"
import tournamentLeaderboard from "./images/backgrounds/tournamentLeaderboard.png"
import tournament from "./images/backgrounds/tournament.png"
import tournament2 from "./images/backgrounds/tournament2.webp"
import tournament2_1 from "./images/backgrounds/tournament2-1.webp"
import tournament2_2 from "./images/backgrounds/tournament2-2.webp"
import mystenlabs from "./images/backgrounds/MystenLabs.webp"

import patch0398 from "./images/backgrounds/patches/patch0398.webp"
import patch040 from "./images/backgrounds/patches/patch040.webp"
import patch0415 from "./images/backgrounds/patches/patch0415.webp"
import patch04270 from "./images/backgrounds/patches/patch04270.webp"
import patch04320 from "./images/backgrounds/patches/patch04320.webp"
import patch04415 from "./images/backgrounds/patches/patch04415.webp"
import patch04520 from "./images/backgrounds/patches/patch04520.webp"
import patch04610 from "./images/backgrounds/patches/patch04610.webp"
import patch04725 from "./images/backgrounds/patches/patch04725.webp"
import patch04830 from "./images/backgrounds/patches/patch04830.webp"
import patch04920 from "./images/backgrounds/patches/patch04920.webp"
import patch05012 from "./images/backgrounds/patches/patch05012.webp"
import patch051 from "./images/backgrounds/patches/patch051.webp"
import patch052 from "./images/backgrounds/patches/patch052.webp"
import patch10 from "./images/backgrounds/patches/patch10.webp"

import EMGtournament from "./images/backgrounds/EMGTournament.webp"
import hotfix046 from "./images/backgrounds/hotfixes/hotfix046.jpg"
import hotfix04275 from "./images/backgrounds/hotfixes/hotfix04275.jpg"
import dpx from "./images/backgrounds/DPX-Arrived.webp"
import coeTournament from "./images/backgrounds/blogs/friendshipTournament/tournament.webp"

import emgFinalistsBody from "./images/backgrounds/blogs/emg-finalists/emg-tournament-winners-body.webp"
import emgFinalistsHead from "./images/backgrounds/blogs/emg-finalists/emg-tournament-winners-head.webp"

import coetoacMain from "./images/backgrounds/blogs/col-to-ac/main.webp"
import coetoacStore from "./images/backgrounds/blogs/col-to-ac/cards-of-ethernity-store.webp"
import coetoacKeeper from "./images/backgrounds/blogs/col-to-ac/keeper-guide-col-to-ac.webp"
import coetoacBot from "./images/backgrounds/blogs/col-to-ac/bot.webp"
import coetoacGif from "./images/backgrounds/blogs/col-to-ac/Secret-Shop-CoL-to-AC-exchange.gif"

// download btn images
import downloadBtn from "./images/miscs/download/button.webp"
import downloadBtnEpic from "./images/miscs/download/button-epic-games-hover.webp"
import downloadBtnUltra from "./images/miscs/download/button-ultra.webp"
import downloadBtnUltraHover from "./images/miscs/download/button-ultra-hover.webp"
import epicIcon from "./images/miscs/download/Epic-games-badge.svg"
import ultraIcon from "./images/miscs/download/Ultra-badge.svg"
import aegLogoDownload from "./images/miscs/download/Aether-Games-logo.svg"
import coeLogoDownload from "./images/miscs/download/Coe-logo.svg"

// BLOG FOOTER IMAGES
import blogFooter1 from "./images/blogfooters/1.webp"
import blogFooter2 from "./images/blogfooters/2.webp"
import blogFooter3 from "./images/blogfooters/3.webp"
import blogFooter4 from "./images/blogfooters/4.webp"
import blogFooter5 from "./images/blogfooters/5.webp"
import blogFooter6 from "./images/blogfooters/6.webp"
import blogFooter7 from "./images/blogfooters/7.webp"

// --TOKENOMICS
import rewards from "./images/backgrounds/Rewards.webp"
import treasury from "./images/backgrounds/Treasury.webp"
import team from "./images/backgrounds/Team.webp"
import marketing from "./images/backgrounds/Marketing.webp"
import advisors from "./images/backgrounds/Advisors.webp"
import balancer from "./images/backgrounds/Balancer.webp"
import seed from "./images/backgrounds/Seed_inv.webp"
import strategic from "./images/backgrounds/Strategic-inv.webp"
import presale from "./images/backgrounds/Public-presale.webp"
import initial from "./images/backgrounds/Initial.webp"
import rewardsHover from "./images/backgrounds/Rewards-hover.webp"
import treasuryHover from "./images/backgrounds/Treasury-hover.webp"
import teamHover from "./images/backgrounds/Team-hover.webp"
import marketingHover from "./images/backgrounds/Marketing-hover.webp"
import advisorsHover from "./images/backgrounds/Advisors-hover.webp"
import balancerHover from "./images/backgrounds/Balancer-hover.webp"
import seedHover from "./images/backgrounds/Seed_inv-hover.webp"
import strategicHover from "./images/backgrounds/Strategic-inv-hover.webp"
import presaleHover from "./images/backgrounds/Public-presale-hover.webp"
import initialHover from "./images/backgrounds/Initial-hover.webp"

import dsSvg from "./icons/Socials/Discord.svg"
import instSvg from "./icons/Socials/Instagram.svg"
import ytbSvg from "./icons/Socials/Youtube.svg"
import twitterSvg from "./icons/Socials/X.webp"
import telegramSvg from "./icons/Socials/Telegram.svg"

import dsHoverSvg from "./icons/Socials/Discord-hover.svg"
import instHoverSvg from "./icons/Socials/Instagram-hover.svg"
import ytbHoverSvg from "./icons/Socials/Youtube-hover.svg"
import twitterHoverSvg from "./icons/Socials/X-hover.webp"
import telegramHoverSvg from "./icons/Socials/Telegram-hover.svg"

// --MONSTERS
import beasts from "./images/backgrounds/beasts.webp"
import beastsHover from "./images/backgrounds/beasts-hover.webp"
import demons from "./images/backgrounds/demons.webp"
import demonsHover from "./images/backgrounds/demons-hover.webp"
import dragons from "./images/backgrounds/dragons.webp"
import dragonsHover from "./images/backgrounds/dragons-hover.webp"
import elementals from "./images/backgrounds/elementals.webp"
import elementalsHover from "./images/backgrounds/elementals-hover.webp"
import fairies from "./images/backgrounds/fairies.webp"
import fairiesHover from "./images/backgrounds/fairies-hover.webp"
import trolls from "./images/backgrounds/trols.webp"
import trollsHover from "./images/backgrounds/trols-hover.webp"

import coins from "./images/backgrounds/coins.webp"
import coinsParticles from "./images/backgrounds/coins-bg-particles.webp"
import aegHeader from "./images/backgrounds/aeg-header.svg"
import aegInfoFrame from "./images/backgrounds/aeg-token-frame.webp"
import aegInfoFlag from "./images/backgrounds/aeg-flag.webp"

// MISCS
import android_banner from "./images/miscs/android_banner.webp"
import ios_banner from "./images/miscs/ios_banner.webp"
import pc_banner from "./images/miscs/pc_banner.webp"
import logoCOE from "./images/miscs/Cards-of-ethernity-logo.webp"
import logoTCG from "./images/logo.webp"
import coe_logo_shine from "./images/miscs/coe-logo-transparent-shine.webp"
import logo_aetherGames from "./images/miscs/aether-games.webp"
import aegInfoLogo from "./images/miscs/aeg-image.webp"
import logoGOE from "./images/miscs/gates-of-ethernity-logo.webp"

import COE_character from "./images/miscs/COE-character.webp"
import landingBanner from "./images/backgrounds/Banner.webp"
import landingBannerMobile from "./images/backgrounds/Banner-mobile.webp"
import cards from "./images/miscs/cards.webp"

import dragon_age1 from "./images/miscs/dragon_age1.webp"
import fairy_age2 from "./images/miscs/fairy.webp"
import troll_age3 from "./images/miscs/troll_age3.webp"

import maw from "./images/miscs/maw.webp"
import goe_sm from "./images/miscs/goe.webp"
import emotesHeart from "./images/miscs/emotes_heart.webp"

import p_06 from "./images/miscs/p_06.webp"
import p_18 from "./images/miscs/p_18.webp"
import p_35 from "./images/miscs/p_35.webp"
import p_45 from "./images/miscs/p_45.webp"
import p_47 from "./images/miscs/p_47.webp"

// VIDEOS

import coe_video from "./videos/coe_video.mp4"
import fire_dragon from "./videos/fire_dragon.mp4"
import corvun from "./videos/corvun.mp4"
import launcher from "./videos/launcher.mp4"
import trailer2 from "./videos/trailer2.mp4"
import trailer_mob from "./videos/trailer_mob.mp4"

import download_launcher from "./videos/COE-Download-launcher-bg.mp4"
import Coe_discover_video from "./videos/Coe-discover-video.mp4"

// ICONS
import g1 from "./icons/info/g1.svg"
import g2 from "./icons/info/g2.svg"
import g3 from "./icons/info/g3.svg"
import discordIcon from "./icons/info/discord.svg"
import key from "./icons/info/key.svg"
import cp from "./icons/info/cp.svg"
import reserv from "./icons/info/reserv.svg"
import heirloom from "./icons/info/heirloom.svg"
import deck from "./icons/info/deck.svg"
import gt5 from "./icons/info/gt5.svg"
import gt from "./icons/info/gt.svg"
import list from "./icons/info/list.svg"
import d_arr from "./icons/info/d_arr.svg"
import fp_down_arr from "./icons/info/down_arr.svg"
import leftArrow from "./icons/left_arrow.svg"
import coeLogo from "./icons/COE-logo.svg"
import copyIcon from "./icons/copy.svg"
import copyIconDark from "./images/founderpacks/svg/copyDark.svg"
import emgBanner from "./images/miscs/EMGBanner.jpg"

// FOUNDERPACKS IMAGES
import bg from "./images/founderpacks/founder_bg.webp"
import fpEthBg from "./images/founderpacks/Aether_BOX_bg.png"
import aSign from "./images/founderpacks/webp/A-sign.webp"
import chestFP from "./images/founderpacks/webp/Chest-FP.webp"
import chestFPhover from "./images/founderpacks/webp/Chest-FP-hover.webp"
import fpEth from "./images/founderpacks/svg/Etherum.svg"
import listIconActive from "./images/founderpacks/svg/list-icon-not-active.svg"
import listIconNotActive from "./images/founderpacks/svg/list-icon-not-active-2.svg"
import arrowBack from "./images/founderpacks/svg/List-icon-active.svg"
import mintButton from "./images/founderpacks/svg/Mint-button.svg"
import mintButtonHover from "./images/founderpacks/svg/Mint-button-hover.svg"
import redeemButton from "./images/founderpacks/svg/redeem-a-key.svg"
import redeemButtonHover from "./images/founderpacks/svg/redeem-a-key-hover.svg"
import redeemPopupClose from "./images/founderpacks/svg/close.svg"
import redeemKeyBG from "./images/backgrounds/redeem-key-bg.webp"
import fpBanner from "./images/founderpacks/webp/FP-banner.webp"
import fpBannerHover from "./images/founderpacks/webp/FP-banner-hover.webp"
import goeBanner from "./images/founderpacks/webp/Goe-banner.webp"
import goeBannerHover from "./images/founderpacks/webp/Goe-banner-hover.webp"

export const icons = {
  exit,
  copy,
  aeg,
  aeg_cards,
  based,
  expand,
  nft,
  pause,
  youtube,
  telegram,
  discord,
  twitter,
  instagram,
  down_arr,
  right_arr,
  play,
  menu,
  Time_icon,
  clock,
  info: {
    g1,
    g2,
    g3,
    gt,
    gt5,
    discordIcon,
    key,
    cp,
    reserv,
    heirloom,
    deck,
  },
  founderIcons: {
    fp_down_arr,
    list,
    d_arr,
    fpEth,
    listIconActive,
    listIconNotActive,
    mintButton,
    mintButtonHover,
    arrowBack,
    redeemPopupClose,
    coeLogo,
    copyIcon,
    redeemButton,
    redeemButtonHover,
    copyIconDark,
  },
  leftArrow,
  discordBtn,
  dsSvg,
  dsHoverSvg,
  instSvg,
  instHoverSvg,
  ytbSvg,
  ytbHoverSvg,
  twitterSvg,
  twitterHoverSvg,
  telegramSvg,
  telegramHoverSvg,
  close,
  left_arr,
  arrow_up,
  emgBanner,
  epicIcon,
  ultraIcon,
  aegLogoDownload,
  coeLogoDownload,
}

export const backgrounds = {
  buttons: {
    rulesButton,
    rulesButtonHover,
    discoverButton,
    discoverButtonHover,
    viewAsNft,
    viewAsNftHover,
    beasts,
    beastsHover,
    demons,
    demonsHover,
    dragons,
    dragonsHover,
    elementals,
    elementalsHover,
    fairies,
    fairiesHover,
    trolls,
    trollsHover,
    rewards,
    rewardsHover,
    treasury,
    treasuryHover,
    team,
    teamHover,
    marketing,
    marketingHover,
    advisors,
    advisorsHover,
    balancer,
    balancerHover,
    seed,
    seedHover,
    strategic,
    strategicHover,
    presale,
    presaleHover,
    initial,
    initialHover,
    btn_bg_dark,
    btn_bg_hover,
    btn_hover,
    download,
    downloadHover,
    accept_btn,
    accept_hover,
    decline_btn,
    decline_hover,
    downloadBtn,
    downloadBtnEpic,
    downloadBtnUltra,
    downloadBtnUltraHover,
  },
  blog: {
    patches: {
      patch0398,
      patch040,
      patch0415,
      patch04270,
      patch04320,
      patch04415,
      patch04520,
      patch04610,
      patch04725,
      patch04830,
      patch04920,
      patch05012,
      patch051,
      patch052,
      patch10,
    },
    hotfixes: {
      hotfix046,
      hotfix04275,
    },
    immublImg,
    ultraImg,
    ultraScreenshot,
    ultiverse,
    howToPlay,
    aetherRaidsImg,
    emotesImg,
    cardsImg,
    emotesNFTUtility1,
    emotesNFTUtility2,
    emotesNFTUtility3,
    founderpacksBg,
    openBetaFaqBg,
    battleSigns,
    tournament,
    tournamentLeaderboard,
    tournament2,
    tournament2img: {
      tournament2_1,
      tournament2_2,
    },
    coeTournament,
    mystenlabs,
    EMGtournament,
    emgFinalists: {
      body: emgFinalistsBody,
      head: emgFinalistsHead,
    },
    dpx,
    colToAc: {
      main: coetoacMain,
      store: coetoacStore,
      keeper: coetoacKeeper,
      bot: coetoacBot,
      gif: coetoacGif,
    },
  },
  blogFooter: [blogFooter1, blogFooter2, blogFooter3, blogFooter4, blogFooter5, blogFooter6, blogFooter7],
  coins,
  coinsParticles,
  aegHeader,
  aegInfoFrame,
  aegInfoFlag,
  age1,
  age2,
  age3,
  age1_bg,
  age2_bg,
  age3_bg,
  shineUnderButton,
  shine2UnderButton,
  shine3UnderButton,
  snow_effect,
  emotesMediumImg,
  emotes2Img,
  download_bg,
}

export const images = {
  android_banner,
  ios_banner,
  pc_banner,
  logoTCG,
  cards,
  COE_character,
  logoCOE,
  logo_aetherGames,
  aegInfoLogo,
  dragon_age1,
  fairy_age2,
  troll_age3,
  maw,
  goe_sm,
  emotesHeart,
  logoGOE,
  coe_logo_shine,
  border_arrow,
  p_06,
  p_18,
  p_35,
  p_45,
  p_47,
  landingBanner,
  landingBannerMobile,
  founderpacks: {
    bg,
    fpEthBg,
    aSign,
    chestFP,
    chestFPhover,
    redeemKeyBG,
    banners: {
      fpBanner,
      fpBannerHover,
      goeBanner,
      goeBannerHover,
    },
  },
}

export const videos = {
  coe_video,
  fire_dragon,
  corvun,
  launcher,
  trailer2,
  download_launcher,
  Coe_discover_video,
  trailer_mob,
}
