import React, { lazy, Suspense } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import TagManager from "react-gtm-module"
import { CookieConsentProvider } from "@use-cookie-consent/react"
import { Web3Provider } from "@ethersproject/providers"
import { Web3ReactProvider } from "@web3-react/core"
import { MetamaskProvider } from "./provider/metamaskprovider"
import { Notification } from "./components/Notification"
import "./index.css"
import "./blog/blog.css"
import SupportPage from "./views/Support"

const Header = lazy(() => import("src/components/Header"))
const Footer = lazy(() => import("src/components/Footer"))
const Landing = lazy(() => import("./views/Landing"))
const Lore = lazy(() => import("./views/Lore"))
const HowToPlay = lazy(() => import("./blog/HowToPlay"))
const Blog = lazy(() => import("./blog/Blog"))
const DownloadLauncher = lazy(() => import("src/views/DownloadLauncher"))
const CookiesBanner = lazy(() => import("./components/CookiesBanner"))

const FounderPacks = lazy(() =>
  import("src/views/FounderPacks").then((module) => ({
    default: module.FounderPacks,
  }))
)

// BLOG POSTS
const UltraXaeg = lazy(() =>
  import("src/blog/UltraXaeg").then((module) => ({
    default: module.UltraXaeg,
  }))
)
const Ultiverse = lazy(() =>
  import("src/blog/Ultiverse").then((module) => ({
    default: module.Ultiverse,
  }))
)
const CardsOfEthernityFAQ = lazy(() =>
  import("src/blog/CardsOfEthernityFAQ").then((module) => ({
    default: module.CardsOfEthernityFAQ,
  }))
)
const EmotesNftUtility = lazy(() =>
  import("src/blog/EmotesNftUtility").then((module) => ({
    default: module.EmotesNftUtility,
  }))
)
const FounderPacksBlogPost = lazy(() =>
  import("src/blog/FounderPacksBlogPost").then((module) => ({
    default: module.FounderPacksBlogPost,
  }))
)
const OpenBetaFAQ = lazy(() =>
  import("src/blog/OpenBetaFAQ").then((module) => ({
    default: module.OpenBetaFAQ,
  }))
)
const BattleSigns = lazy(() =>
  import("src/blog/BattleSigns").then((module) => ({
    default: module.BattleSigns,
  }))
)
const Tournament = lazy(() =>
  import("src/blog/Tournament").then((module) => ({
    default: module.Tournament,
  }))
)
const FractalTournament = lazy(() =>
  import("src/blog/FractalTournament").then((module) => ({
    default: module.FractalTournament,
  }))
)
const MystenLabs = lazy(() =>
  import("src/blog/MystenLabs").then((module) => ({
    default: module.MystenLabs,
  }))
)
const DeluxePacks = lazy(() =>
  import("src/blog/DeluxePacks").then((module) => ({
    default: module.DeluxePacks,
  }))
)

const ColToAC = lazy(() =>
  import("src/blog/ColToAC").then((module) => ({
    default: module.ColToAC,
  }))
)

const JuneCoeTournament = lazy(() =>
  import("src/blog/JuneCoeTournament").then((module) => ({
    default: module.JuneCoeTournament,
  }))
)

// PATCHES
const Patch0398 = lazy(() =>
  import("src/blog/Patch0398").then((module) => ({
    default: module.Patch0398,
  }))
)
const Patch040 = lazy(() =>
  import("src/blog/Patch040").then((module) => ({
    default: module.Patch040,
  }))
)
const Patch0415 = lazy(() =>
  import("src/blog/Patch0415").then((module) => ({
    default: module.Patch0415,
  }))
)

const Patch04270 = lazy(() =>
  import("src/blog/Patch04270").then((module) => ({
    default: module.Patch04270,
  }))
)
const Patch04320 = lazy(() =>
  import("src/blog/Patch04320").then((module) => ({
    default: module.Patch04320,
  }))
)

const Patch04415 = lazy(() =>
  import("src/blog/Patch04415").then((module) => ({
    default: module.Patch04415,
  }))
)
const Patch04520 = lazy(() =>
  import("src/blog/Patch04520").then((module) => ({
    default: module.Patch04520,
  }))
)
const Patch04610 = lazy(() =>
  import("src/blog/Patch04610").then((module) => ({
    default: module.Patch04610,
  }))
)
const Patch04725 = lazy(() =>
  import("src/blog/Patch04725").then((module) => ({
    default: module.Patch04725,
  }))
)
const Patch04830 = lazy(() =>
  import("src/blog/Patch04830").then((module) => ({
    default: module.Patch04830,
  }))
)
const Patch04920 = lazy(() =>
  import("src/blog/Patch04920").then((module) => ({
    default: module.Patch04920,
  }))
)
const Patch05012 = lazy(() =>
  import("src/blog/Patch05012").then((module) => ({
    default: module.Patch05012,
  }))
)
const Patch051 = lazy(() =>
  import("src/blog/Patch051").then((module) => ({
    default: module.Patch051,
  }))
)
const Patch05145 = lazy(() =>
  import("src/blog/Patch05145").then((module) => ({
    default: module.Patch05145,
  }))
)

const Patch052 = lazy(() =>
  import("src/blog/Patch052").then((module) => ({
    default: module.Patch052,
  }))
)

const Patch10 = lazy(() =>
  import("src/blog/Patch10").then((module) => ({
    default: module.Patch10,
  }))
)

// HOTFIXES
const Hotfix046 = lazy(() =>
  import("src/blog/Hotfix046").then((module) => ({
    default: module.Hotfix046,
  }))
)
const Hotfix04275 = lazy(() =>
  import("src/blog/Hotfix04275").then((module) => ({
    default: module.Hotfix04275,
  }))
)

const CookiePolicy = lazy(() => import("./views/CookiePolicy"))
const PrivacyPolicy = lazy(() => import("./views/PrivacyPolicy"))
const TermsOfService = lazy(() => import("./views/TermsOfService"))

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

const tagManagerArgs = {
  gtmId: "G-NRZB72XZ2T",
}

TagManager.initialize(tagManagerArgs)

const rootElement = document.getElementById("root")

ReactDOM.render(
  <BrowserRouter>
    <Web3ReactProvider getLibrary={getLibrary}>
      <CookieConsentProvider>
        <Suspense fallback={<div style={{ width: "100vw", height: "100vh", backgroundColor: "#1c1c1c" }} />}>
          <Header />
          <Routes>
            <Route exact path="/" element={<Blog />} />
            {/* <Route exact path="/lore" element={<Lore />} /> */}
            {/* <Route exact path="/home" element={<Landing />} /> */}
            {/* <Route exact path="/mint" element={<FounderPacks />} /> */}
            {/* <Route exact path="/blog" element={<Blog />} /> */}
            <Route exact path="/blog" element={<Navigate to="/" replace />} />

            <Route exact path="/founderpack-faq" element={<FounderPacksBlogPost />} />
            <Route exact path="/openbeta-faq" element={<OpenBetaFAQ />} />
            <Route exact path="/faq" element={<CardsOfEthernityFAQ />} />
            <Route exact path="/emotes-nft-utility" element={<EmotesNftUtility />} />
            {/* <Route exact path="/ultra-x-aethergamesinc" element={<UltraXaeg />} /> */}
            {/* <Route exact path="/ultiverse-x-aethergames" element={<Ultiverse />} /> */}
            <Route exact path="/how-to-play" element={<HowToPlay />} />
            <Route exact path="/download" element={<DownloadLauncher />} />
            {/* <Route exact path="/battlesigns" element={<BattleSigns />} /> */}
            <Route exact path="/tournament" element={<Tournament />} />
            <Route exact path="/pre-season-tournament" element={<FractalTournament />} />
            {/* <Route exact path="/mysten-labs-aether-games" element={<MystenLabs />} /> */}
            <Route exact path="/deluxe-packs" element={<DeluxePacks />} />
            <Route exact path="/col-to-ac" element={<ColToAC />} />
            <Route exact path="/friendship-tournament-june-9th-27th" element={<JuneCoeTournament />} />

            {/* PATCH NOTES */}
            <Route exact path="/patch-notes-0-39-8" element={<Patch0398 />} />
            <Route exact path="/patch-notes-0-4-0" element={<Patch040 />} />
            <Route exact path="/patch-notes-0-41-5" element={<Patch0415 />} />
            <Route exact path="/patch-notes-0-42-70" element={<Navigate to="/patch-notes-0-42-71" replace />} />
            <Route exact path="/patch-notes-0-42-71" element={<Patch04270 />} />
            <Route exact path="/patch-notes-0-43-20" element={<Patch04320 />} />
            <Route exact path="/patch-notes-0-44-15" element={<Patch04415 />} />
            <Route exact path="/patch-notes-0-45-20" element={<Patch04520 />} />
            <Route exact path="/patch-notes-0-46-10" element={<Patch04610 />} />
            <Route exact path="/patch-notes-0-47-25" element={<Patch04725 />} />
            <Route exact path="/patch-notes-0-48-30" element={<Patch04830 />} />
            <Route exact path="/patch-notes-0-49-20" element={<Patch04920 />} />
            <Route exact path="/patch-notes-0-50-12" element={<Patch05012 />} />
            <Route exact path="/patch-notes-0-51" element={<Patch051 />} />
            <Route exact path="/patch-notes-0-51-45" element={<Patch05145 />} />
            <Route exact path="/patch-notes-0-52" element={<Patch052 />} />
            <Route exact path="/patch-notes-1-0" element={<Patch10 />} />

            {/* HOTFIXES */}
            <Route exact path="/hotfix-0-4-6" element={<Hotfix046 />} />
            <Route exact path="/hotfix-0-42-75" element={<Hotfix04275 />} />

            {/* DO NOT REMOVE THIS REDIRECT, REQUIRED FOR EPIC TO WORK */}
            <Route exact path="/assistant" element={<Navigate to="/support" replace />} />
            <Route exact path="/support" element={<SupportPage />} />
            <Route exact path="/cookies-policy" element={<CookiePolicy />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms-of-service" element={<TermsOfService />} />
            <Route exact path="/*" element={<Blog />} />
          </Routes>
          <CookiesBanner />
          <Notification />
          <Footer />
        </Suspense>
      </CookieConsentProvider>
    </Web3ReactProvider>
  </BrowserRouter>,
  rootElement
)
